import {
  Accordion,
  Box,
  Flex
} from '@chakra-ui/react';
import { BrandCourseCategoryCard } from '../../ui/BrandCourseCategoryCard/BrandCourseCategoryCard';
import { BrandPageLayout } from '../../ui/BrandLayout/BrandPageLayout';
import { PcBrandOptionModal } from '../../ui/Pc/PcBrandOptionModal';
import { SpBrandCourseAccordion } from '../../ui/Sp/SpBrandCourseAccordion';
import {
  ContentWrapper,
  PcDisplayBlock,
  SpDisplayBlock,
} from '@/components';
import { Image } from '@/components/Image';
import {
  BrandCard,
  CourseCategory,
  HeadingImage,
  QuestionAndAnswer,
  SecondViewContents,
  ThemeColors,
  TopViewContents
} from '@/features/brand/types/brand';

export type BellepocBrandPageProps = {};

export const BellepocBrandPage = ({}: BellepocBrandPageProps) => {

  return (
    <BrandPageLayout
      topViewContents={topViewContents}
      secondViewContents={secondViewContents}
      brandCards={brandCards}
      headingImage={brandHeadingImage}
      themeColors={themeColors}
      questionAndAnswers={questionsAndAnswers}
      sliderHeight={'-145px'}
    >
      <ContentWrapper
        py={{ base: 30, lg: 70 }}
        bg={{ base: 'white', lg: themeColors.brandBgColor }}
      >
        <Box
          position={'relative'}
          height={'60px'}
          mt={4}
          mb={10}
          as={'h2'}
        >
          <Image
            src={'/images/heading_title/sp/menu_bell.png'}
            alt={'MENU メニュー'}
            fill
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <PcDisplayBlock>
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
          >
            {courseCategories.map((courseCategoryCard, index) =>
              <BrandCourseCategoryCard
                key={index}
                courseCategoryCard={courseCategoryCard}
                themeColors={themeColors}
              />
            )}
          </Flex>
        </PcDisplayBlock>
        <SpDisplayBlock
          px={4}
        >
          <Accordion
            defaultIndex={[0]}
            allowMultiple={true}
            width={'95%'}
            margin={'0 auto'}
          >
            {courseCategories.map((courseCategoryCard, index) =>
              <SpBrandCourseAccordion
                key={index}
                courseCategoryCard={courseCategoryCard}
                themeColors={themeColors}
              />
            )}
          </Accordion>
        </SpDisplayBlock>
      </ContentWrapper>

      <ContentWrapper
        py={{ base: 30, lg: 70 }}
        bg={{ base: 'white', lg: themeColors.brandBgColor }}
      >
        <PcDisplayBlock>
          <Box
            position={'relative'}
            height={'55px'}
            mt={4}
            mb={10}
            as={'h2'}
          >
            <Image
              src={'/images/heading_title/sp/option_menu_bell.png'}
              alt={'OPTION MENU オプションメニュー'}
              fill
              style={{ objectFit: 'contain' }}
            />
          </Box>
          <Flex
            justifyContent={'center'}
            flexWrap={'wrap'}
          >
            {optionCards.map((courseOptionCard, index) =>
              <PcBrandOptionModal
                key={index}
                courseCategoryCard={courseOptionCard}
                themeColors={themeColors}
              />
            )}
          </Flex>
        </PcDisplayBlock>

        <SpDisplayBlock
          px={4}
        >
          <Box
            position={'relative'}
            height={'55px'}
            mt={4}
            mb={10}
          >
            <Image
              src={'/images/heading_title/sp/option_menu_bell.png'}
              alt={'OPTION MENU オプションメニュー'}
              fill
              style={{ objectFit: 'contain' }}
            />
          </Box>
          <Accordion
            defaultIndex={[]}
            allowMultiple={true}
            width={'95%'}
            margin={'0 auto'}
          >
            {optionCards.map((courseOptionCard, index) =>
              <SpBrandCourseAccordion
                isOption={true}
                key={index}
                courseCategoryCard={courseOptionCard}
                themeColors={themeColors}
              />
            )}
          </Accordion>
        </SpDisplayBlock>
      </ContentWrapper>
    </BrandPageLayout>
  );
};

const themeColors: ThemeColors = {
  brandColor: 'bellepocBrand.500',
  brandBgColor: 'bellepocBrand.100',
  brandBgSubColor: 'rgba(69, 176, 53, 0.4)',
  courseTextColor: 'bellepocBrand.300',
};

const brandPath = '/images/brand/bellepoc';
const courseImgPath = `${brandPath}/course`;
const optionImgPath = `${brandPath}/option`;

const brandHeadingImage: HeadingImage = {
  headingAboutImage: '/images/heading_title/sp/about_bell.png',
  headingFaqImage: '/images/heading_title/sp/faq_bell.png',
  pcHeadingBrandConceptImage: '/images/heading_title/pc/brand_concept_reraku.png',
  spHeadingBrandConceptImage: '/images/heading_title/sp/brand_concept_reraku.png'
};

export const topViewContents: TopViewContents = {
  pcHeadingImageUrl: '/images/heading_title/pc/brand_concept_bell.png',
  spHeadingImageUrl: '/images/heading_title/sp/brand_concept_bell.png',
  headingAlt: 'BellEpoc ブランドコンセプト',
  pcTopImageUrl: '/images/brand/bellepoc/pc/brand_bellepoc_top.jpg',
  spTopImageUrl: '/images/brand/bellepoc/sp/brand_bellepoc_top.jpg',
  topImageAlt: 'BellEpoc',
  brandLogoImage: '/images/logo/bellepoc_logo.png',
  brandName: 'BellEpoc',
};

export const secondViewContents: SecondViewContents = {
  brandLogoImage: '/images/logo/bellepoc_logo.png',
  brandName: 'BellEpoc',
  subHeading: '疲れをためないココロとカラダのバランスを。',
  subDescription: '心と身体のバランスを整え、定期的にメンテナンスをすることを大切にしています。これからも日常の疲れを癒し、お客様の健康維持・増進をサポートして、「ストレスフルからストレスフリーなライフスタイル」をご提供してまいります。',

  secondViewImage: '/images/brand/bellepoc/brand_bell_second.jpg',
  secondViewImageAlt: 'BellEpoc ブランドトップ',
  buttonColor: themeColors.brandColor
};


export const brandCards: BrandCard[] = [
  {
    brandImageUrl: '/images/brand/bellepoc/sp/about_bell_space.jpg',
    brandImagePcUrl: '/images/brand/bellepoc/pc/about_bell_space.jpg',
    brandAltText: '安心安全で安らぎの空間を',
    brandHeading: `安全で安心な
                  安らぎの時間を`,
    brandDescription: `安らぎの空間でバランスを整えて、疲れを溜めない、疲れにくい身体へとメンテナンスサポートしていきます。
                      大切なお客様のお身体に触れますので、業界基準以上の安全なリラクゼーションを提供します。`,
  },
  {
    brandImageUrl: '/images/brand/reraku/sp/about_reraku_communication.jpg',
    brandImagePcUrl: '/images/brand/reraku/pc/about_reraku_communication.jpg',
    brandAltText: 'コミュニケーション',
    brandHeading: 'コミュニケーション',
    brandDescription: '私たちは、お客様の心と身体の悩みを緩和するためのご提案をするプランナーです。施術中には、お客様のお身体の悩みをヒアリング。日常のお話も交えながら、お客様にとって心地よく気持ちよい時間になるようなコミニケーションを目指しています。',
  },
  {
    brandImageUrl: '/images/brand/brand_top/about/education.jpg',
    brandImagePcUrl: '/images/brand/brand_top/about/education.jpg',
    brandAltText: 'セラピスト教育',
    brandHeading: 'セラピスト教育',
    brandDescription: 'リラクカレッジと呼ばれる業界最大級の研修施設でテストに合格したスタッフのみが店頭に立つことを許されます。日々スキルアップに励むためのランク制度を用いてベテランも新人も常に高いモチベーションを保っています',
    pagePath: 'education',
    linkImage: `${brandPath}/more.png`
  },
];

export const courseCategories: CourseCategory[] = [
  {
    courseCategoryImageUrl: `${courseImgPath}/body_care.jpg`,
    courseCategoryName: 'ボディケア',
    courseCategoryId: 14,
    courseCategoryDescription: `お疲れの箇所を中心に、全身をほぐしていきます。
                            特に上半身のお疲れが気になる方におすすめです。`,
    courseCategoryDetails: [
      {
        time: 40,
        price: 3850,
        description: '気になる部分中心にほぐします。',
      },
      {
        time: 60,
        price: 6050,
        description: '気になる部分を中心に、背面全身をほぐします。',
      },
      {
        time: 80,
        price: 7700,
        description: '気になる部分をじっくりほぐし、背面・前面をしっかりほぐします。',
      },
    ],
  },
  {
    courseCategoryImageUrl: `${courseImgPath}/aroma_body_care.jpg`,
    courseCategoryName: 'アロマボディケア',
    courseCategoryId: 16,
    courseCategoryDescription: `アロマオイルを塗布して全身をほぐすことで、血液やリンパの流れを促します。
                            アロマオイルと手のひらの温もりによる施術でリラックス効果が期待できます。`,
    courseCategoryDetails: [
      {
        time: 50,
        price: 6950,
        description: `背面を中心に全身をほぐします。
                      アロマオイルを使った全身のケアを体験してみたい方におすすめです。`,
      },
      {
        time: 70,
        price: 8600,
        description: `うつ伏せと仰向けの姿勢で、全身をほぐします。日常のストレスから離れてリラックスしたい方や、冷えが気になる方にもおすすめです。
                      背面に加えてデコルテをほぐすので、首・肩のお疲れの方にもおすすめです。`,
      },
      {
        time: 90,
        price: 10830,
        description: 'うつ伏せと仰向けの姿勢で、全身をじっくりほぐします。背面全身から仰向けの姿勢でデコルテ・腕・足もしっかりほぐすので、全身のお疲れが強い方におすすめです。',
      },
      {
        time: 120,
        price: 13620,
        description: `うつ伏せと仰向けの姿勢で、全身をじっくりほぐします。
                    全身のお疲れが強い方はもちろん、ストレスが強い方や、眠りが浅いことがお悩みの方にもおすすめです。`,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${courseImgPath}/reflexology.jpg`,
    courseCategoryName: 'リフレクソロジー',
    courseCategoryId: 15,
    courseCategoryDescription: `アロマオイルやクリームを使用して、足全体をほぐします。
                            足裏にある反射躯を刺激することで、血行促進の効果や内臓系の活性化が期待できます。`,
    courseCategoryDetails: [
      {
        time: 20,
        price: 2850,
        description: '足裏の反射区を中心にしてアロマオイルを使用し刺激していきます。',
      },
      {
        time: 40,
        price: 4500,
        description: '足裏の反射区に加えて、ふくらはぎもしっかり流していきます。デスクワーク・立ち仕事などで、足が疲れやすい方におすすめです。',
      },
      {
        time: 60,
        price: 6150,
        description: '足裏の反射区からふくらはぎまでしっかりケアしていきます。足全体のお疲れがなかなか取れない方におすすめのコースです。',
      },
    ],
  },
  {
    courseCategoryImageUrl: `${courseImgPath}/dead_skin_care.jpg`,
    courseCategoryName: '角質ケア',
    courseCategoryId: 17,
    courseCategoryDescription: '固くなってしまった足裏の古い角質をやすりで表面を滑らかにします。 足裏の角質を除去することで、すべすべの素足を目指しましょう。',
    courseCategoryDetails: [
      {
        time: 40,
        price: 4400,
        description: '足裏の角質を取り除き保湿していきます。足の乾燥やざらつきが気になる方にオススメです。',
      },
      {
        time: 60,
        price: 6700,
        description: '角質をケアした後、アロマオイルで足裏の反射区を刺激していきます。',
      },
      {
        time: 80,
        price: 8350,
        description: '角質をケアした後、足裏に加えてふくらはぎまでアロマオイルで流します。',
      },
    ],
  },
];




export const optionCards: CourseCategory[] = [
  {
    courseCategoryImageUrl: `${optionImgPath}/eye_head_care.jpg`,
    courseCategoryName: 'アイヘッドケア',
    courseCategoryId: 36,
    courseCategoryDescription: `顔や頭の筋肉のコリをほぐして、緊張を解きます。血行の促進で、眼の疲れや肩の疲れの解消が期待できます。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 10,
        price: 1100,
      },
      {
        time: 20,
        price: 2200,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${optionImgPath}/hand_care.jpg`,
    courseCategoryName: 'ハンドケア',
    courseCategoryId: 42,
    courseCategoryDescription: `疲れた手や腕をクリームを使ってほぐしていきます。デスクワークや細かい作業などで疲れている方におすすめのパーツケアです。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 20,
        price: 2850,
      },
      {
        time: 30,
        price: 3950,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${optionImgPath}/belly_care.jpg`,
    courseCategoryName: 'おなかケア',
    courseCategoryId: 43,
    courseCategoryDescription: `様々な理由で固くなって機能が低下してしまった大腸や小腸をほぐします。このバウエルケアの刺激で、小腸・大腸の働きを正常に戻し、代謝・消化・吸収能力アップを目指します。
                            また同時に腹筋のストレッチによる骨盤のバランス調整や、横隔膜のストレッチによる自律神経のバランス調整にも役立ちます。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 10,
        price: 1100,
      },
      {
        time: 20,
        price: 2200,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${optionImgPath}/decollete.jpg`,
    courseCategoryName: 'デコルテアロマ',
    courseCategoryId: 52,
    courseCategoryDescription: `首・肩・デコルテをアロマオイルを使ってほぐします。首の縮まりやリンパの滞りが気になる方におすすめです。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 10,
        price: 1750,
      },
      {
        time: 20,
        price: 2850,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${optionImgPath}/shoulder_neck_stretch.jpg`,
    courseCategoryName: '肩くびストレッチ',
    courseCategoryId: 44,
    courseCategoryDescription: `テレワークなどで凝り固まった肩と首に特化して伸ばします。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 20,
        price: 3300,
      },
      {
        time: 40,
        price: 6600,
      },
    ],
  },
  {
    courseCategoryImageUrl: `${optionImgPath}/calf_care.jpg`,
    courseCategoryName: 'ふくらはぎケア',
    courseCategoryId: 51,
    courseCategoryDescription: `リフレクソロジーとは異なる手技でふくらはぎをオイルを使用しながら、下から上にしっかり流します。
                            ※メインのコースと併用してご予約ください（単品利用不可）`,
    courseCategoryDetails: [
      {
        time: 20,
        price: 2850,
      },
      {
        time: 30,
        price: 3950,
      },
    ],
  },
];

export const questionsAndAnswers: QuestionAndAnswer[] = [
  {
    question: '今度受けてみたいのですが、お店に行くには予約は必要ですか？',
    answer: `ご予約いただかなくてもご利用できます。ただ店舗の予約状況によりご希望のお時間に
    ご案内できない可能性がございますので、ご予約いただくことをおすすめいたします。`
  },
  {
    question: '男性も利用出来るのでしょうか？',
    answer: '当ブランドでは男性の方にも多くご利用いただいております。ぜひお気軽にお立ちより下さい。'
  },
  {
    question: 'アイヘッドケア（頭周り、お目元周り）の施術を受ける時には、コンタクトやお化粧は落とさないとダメですか？',
    answer: 'コンタクトやお化粧は落とさなくても大丈夫ですがタオル越しの施術の為、お化粧が落ちてしまう可能性があります。気になる際にはお気軽にスタッフまでお声がけください。'
  },
];
